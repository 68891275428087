<template>
	<b-modal id="stencil-confirmation" ref="stencil-confirmation-modal" title="Generate Stencil Form" @show="onReset"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-form ref="form">
			<b-form-group id="assetcode-input-group" label="Asset Code" label-for="assetCode"
				description="* This field only allows 4 characters with capital letters and numbers.">
				<b-form-input id="assetCode" name="Asset Code" v-model="form.startAssetCode" placeholder="Enter Asset Code"
					v-validate="'required|alpha_num|min:4|max:4|regex:^[A-Z0-9]*$'" />
				<span v-show="errors.has('Asset Code')" class="help-block">{{
					errors.first('Asset Code')
				}}</span>
			</b-form-group>

			<b-form-group id="purpose-input-group" label="Purpose" label-for="assetCode">
				<b-form-textarea id="purpose" v-model="form.purpose" type="text" name="Purpose" placeholder="Enter Purpose"
					v-validate="{
						required: true,
						regex: remarksRegex,
					}" />
				<span v-show="errors.has('Purpose')" class="help-block">{{
					errors.first('Purpose')
				}}</span>
			</b-form-group>
		</b-form>

		<div v-if="stencil.length !== 0">
			This will generate Stencil Form starting
			<strong>{{ stencil[stencil.length - 1] }} - {{ stencil[0] }}</strong> ({{
				stencil.length
			}}).
		</div>

		<template #modal-footer>
			<b-button class="float-right" @click="onCancel"> Cancel </b-button>
			<b-button :variant="okVariant" class="float-right" @click="onSubmit">
				{{ okTitle }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
// Util
import { generateExcelFile } from '@/utils/stencilUtil';
import { DateUtil } from '@/utils/dateutil';

// API & Database
import stencilDAO from '@/database/stencils';
import api from '@/api/stencilApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import EventBus from '@/shared/event-bus';

export default {
	name: 'stencil-confirmation',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			stencil: [],
			form: {
				startAssetCode: '',
				lastAssetCode: '',
				purpose: '',
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: this.$store.getters['email'],
			},
			okTitle: 'Submit',
			okVariant: 'primary',
			loggedUser: this.$store.getters.loggedUser,
		};
	},
	computed: {
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	mounted() {
		EventBus.$on('showStencilConfirmationDialog', () => {
			this.$bvModal.show('stencil-confirmation');
		});
	},
	methods: {
		async onSubmit(evt) {
			evt.preventDefault();

			const isValid = await this.$validator.validateAll();
			const assetCode = this.form.startAssetCode;
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			} else if (
				assetCode.includes('i') ||
				assetCode.includes('l') ||
				assetCode.includes('o')
			) {
				this.$toaster.warning('Asset Code must not contain letter i, l or o');
				return;
			}

			if (this.stencil.length !== 0) {
				this.onConfirm();
			} else {
				this.onGenerate();
			}
		},
		onCancel() {
			this.onReset();
			this.$bvModal.hide('stencil-confirmation');
		},
		async onGenerate() {
			this.isLoading = true;

			try {
				const { data } = await api.generateStencil(this.form.startAssetCode, this.loggedUser.id);
				this.stencil = data;
				this.form.lastAssetCode = this.stencil[0];

				// Update UI
				this.okTitle = 'Generate Excel File';
				this.okVariant = 'warning';
			} catch (_error) {
				this.$toaster.error('Error generating stencil form. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async onConfirm() {
			this.isLoading = true;

			try {
				await stencilDAO.create(this.form);

				this.$toaster.success('Stencil Form is now generating...');
				this.onGenerateFile();

				EventBus.$emit('onCreateSuccess');
				this.onCancel();
			} catch (_error) {
				this.$toaster.error('Error creating stencil.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async onGenerateFile() {
			await generateExcelFile(this.stencil);
		},
		onReset() {
			this.okTitle = 'Submit';
			this.okVariant = 'primary';
			this.stencil = [];
			this.form = {
				startAssetCode: '',
				lastAssetCode: '',
				purpose: '',
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: this.$store.getters['email'],
			};

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
	beforeDestroy() {
		EventBus.$off('showStencilConfirmationDialog');
		this.onReset();
	},
};
</script>
