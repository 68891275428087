import moment from 'moment';
import ExcelJS from 'exceljs';
import { FileUtil } from './fileUtil';


const config = {
    fonts: {
        header: {
            name: 'Calibri',
            size: 20
        },
        rackHeaders: {
            name: 'Calibri',
            size: 11,
            bold: true
        },
        assetCodes: {
            name: 'Calibri',
            size: 12
        }
    },
    center: { vertical: 'middle', horizontal: 'center' },
    border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    },
    fill: {
        type: 'pattern',
        pattern: 'darkVertical',
        fgColor: { argb: 'DBD9DC' }
    }
};

// Stencil header
export const excelHeaders = {
    17: 'A', 16: 'B', 15: 'C', 14: 'D', 13: 'E', 12: 'F', 11: 'G', 10: 'H', 9: 'I',
    8: 'J', 7: 'K', 6: 'L', 5: 'M', 4: 'N', 3: 'O', 2: 'P', 1: 'Q'
};

export const filename = 'Stencil_' + moment().format('MMDDYYYY');

export const groupStencilByRack = stencils => {
    // Stencil Rack Pattern
    let racks = 17;
    let rotateEvery = 14;
    let currRack = 0;
    let pallets = [];
    let result = [];

    for (const stencil of stencils) {
        currRack++;

        const rack = { rackNo: 0, pallets: [] };
        pallets.push(stencil);

        if (currRack === rotateEvery) {
            rack.rackNo = racks;
            rack.pallets = pallets.reverse();
            result.push(rack);

            // Decrease racks
            if (racks !== 0) racks--;

            // Reset rotation
            currRack = 0;
            pallets = [];
        }
    }

    return result;
}

export const generateExcelFile = async data => {
    const stencil = groupStencilByRack(data);

    // Setup excel
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'TAWITech';
    workbook.created = new Date();

    // Create sheet from workbook
    const worksheet = workbook.addWorksheet('Stencil');
    worksheet.pageSetup.horizontalCentered = true;
    worksheet.pageSetup.verticalCentered = true;
    worksheet.pageSetup.showGridLines = true;

    // Set document header
    worksheet.mergeCells('A1:Q1');
    worksheet.getCell('A1').value = 'STENCIL INSTRUCTION FORM';
    worksheet.getCell('A1').alignment = config.center;
    worksheet.getCell('A1').font = config.fonts.header;

    let cell;
    for (const rack of stencil) {
        // Set Rack Headers
        const col = excelHeaders[rack.rackNo];
        cell = worksheet.getCell(`${col}2`);
        cell.value = `Rack${rack.rackNo}`;
        cell.font = config.fonts.rackHeaders;
        cell.alignment = config.center;
        cell.border = config.border;

        let currRow = 3; // Start populate assets at row 3
        for (const element of rack.pallets) {
            cell = worksheet.getCell(`${col}${currRow}`);
            cell.value = element;
            cell.font = config.fonts.assetCodes
            cell.alignment = config.center;
            cell.border = config.border;
            if (currRow % 2 !== 0) cell.fill = config.fill; // Fill row every odd numbers
            currRow++;
        }
    }

    // Download excel file
    const buffer = await workbook.xlsx.writeBuffer();
    FileUtil.saveByteArray(filename, buffer, 'xlsx');
}