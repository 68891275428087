<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-tags"></i> Stencil Generator</b-card-title>
			<b-card-sub-title>Automate stencil generation</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
				<b-card-text class="mt-4">
					<b-row>
						<b-col sm="6" md="3" class="mt-4 mb-2">
							<b-dropdown text=" Select Actions " variant="dark">
								<b-dropdown-item @click="generateStencil">
									<i class="fa fa-plus"></i>&nbsp; Create New Stencil
								</b-dropdown-item>
							</b-dropdown>
						</b-col>
						<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
							<b-input-group prepend="Show" append="/ Page">
								<b-form-select :options="pageOptions" v-model="perPage" />
							</b-input-group>
						</b-col>
					</b-row>

					<b-table :items="items" :fields="fields" :responsive="true" selected-variant="primary"
						:current-page="currentPage" :per-page="perPage" show-empty>
						<template v-slot:cell(dateCreated)="row">{{ row.item.dateCreated | toDateTimeString
							}}</template>

						<template v-slot:cell(purpose)="row">
							<span class="truncate-text">
								<truncate collapsed-text-class="collapsed" clamp="Show More" :length="25"
									less="Show Less" type="html" :text="breakPurpose(row.item.purpose, 25)" />
							</span>
						</template>

						<template v-slot:cell(actions)="row">
							<b-button size="sm" variant="primary" class="mr-1"
								@click="onGenerateFile(row.item.startAssetCode)">
								Generate File
							</b-button>
						</template>
					</b-table>
				</b-card-text>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ items.length }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="items.length" :per-page="perPage"
							v-model="currentPage" class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<StencilConfirmation />
	</div>
</template>


<script>
// Component
import StencilConfirmation from './stencilGenerator/StencilConfirmation';

// Util
import { generateExcelFile } from '@/utils/stencilUtil';

// API & Database
import api from '@/api/stencilApi';
import stencilDAO from '@/database/stencils';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import EventBus from '@/shared/event-bus';
import truncate from 'vue-truncate-collapsed';

export default {
	name: 'stencil-generator',
	components: {
		StencilConfirmation,
		JsonExcel,
		Loading,
		truncate
	},
	data() {
		return {
			eventBus: EventBus,
			items: [],
			fields: [
				{ key: 'startAssetCode', label: 'Start Asset Code' },
				{ key: 'lastAssetCode', label: 'Last Asset Code' },
				{ key: 'purpose', label: 'Purpose' },
				{ key: 'dateCreated', label: 'Date Created', sortable: 'true' },
				{ key: 'createdBy', label: 'Created By' },
				{
					key: 'actions',
					class: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filterBy: {},
			loggedUser: this.$store.getters.loggedUser,
			isLoading: false,
		};
	},
	mounted() {
		setTimeout(async () => {
			try {
				// show loading indicator
				this.isLoading = true;

				// Filter Access
				if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				await this.retrieveStencils();

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		EventBus.$on('onGenerationSuccess', async () => await this.retrieveStencils());
		EventBus.$on('onCreateSuccess', async () => await this.retrieveStencils());
	},
	methods: {
		async onFilterRequest() {
			await this.retrieveStencils();
		},
		async resetFilters() {
			await this.retrieveStencils();
		},
		async retrieveStencils() {
			try {
				// show loading indicator
				this.isLoading = true;

				const stencilsObj = await stencilDAO.retrieve(this.filterBy);
				this.items = Object.values(stencilsObj);
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		generateStencil() {
			EventBus.$emit('showStencilConfirmationDialog');
		},
		async onGenerateFile(assetCode) {
			this.$toaster.success('Stencil Form is now generating...');
			const { data } = await api.generateStencil(assetCode, this.loggedUser.id);
			await generateExcelFile(data);
		},
		breakPurpose(purpose, length) {
			return purpose.length > length ? purpose.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : purpose;
		}
	},
	beforeDestroy() { 
		EventBus.$off('onGenerationSuccess');
	},
};
</script>